<template>
  <v-row v-if="!rendering" class="pa-6" style="flex-wrap: nowrap">
    <v-tooltip :disabled="!btn.label"
               bottom
               v-for="btn in child"
               :key="btn.event"
               color="secondary">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
               @click.stop.prevent="handle(btn.event, $event)"
               fab
               v-if="buttonVisible(btn)"
               small
               elevation="2"
               v-bind="attrs"
               v-on="on"
               class="mr-2"
        >
          <v-icon color="#666666"
          > {{btn.icon}}</v-icon>
        </v-btn>
      </template>
      <span class="text--white">{{ btn.label }}</span>
    </v-tooltip>
  </v-row>
  <v-row v-else>
    <v-progress-circular
      :size="20"
      color="#0B583F"
      indeterminate
      :width="3"
    ></v-progress-circular>
  </v-row>
</template>

<script>
export default {
  name: 'tableActions',
  props: {
    item: {
      default: () => {},
    },
    child: {
      default: () => [],
    },
  },
  computed: {
    rendering() {
      return this.$store.getters.loading;
    },
  },
  methods: {
    handle(type, event) {
      this.$emit('set', { type, event });
    },
    buttonVisible(btn) {
      return btn.visible || this.item[btn.availableConditionName]
    }
  },
};
</script>

<style scoped>

</style>
