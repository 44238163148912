<template>
  <v-container fluid class="pageable-table notranslate" :style="cssProps">
    <v-row class="mt-1">
      <v-toolbar v-if="toolbarShown" flat>
        <label v-if="config.title" class="filters__title-label"> {{ config.titleLabel }}</label>
        <v-btn
          v-if="config.add && availablePages[$route.name].create"
          rounded
          color="primary"
          class="mr-3"
          large
          @click="$emit('handleActions', { type: 'create', item: null })"
        >
          <v-icon left v-if="config.addIcon">
            mdi-plus
          </v-icon>
          {{ addButtonTitle ? addButtonTitle : "Добавить" }}
        </v-btn>
        <slot name="first-slot" />
        <form
          class="search-form"
          :id="random"
          action=""
          v-if="config.search"
          :class="options.search ? 'search-active' : ''"
          @keydown.enter.prevent.stop=""
        >
          <label>
            <input
              class="search-input"
              type="search"
              :placeholder="searchPlaceholder"
              :value="options.search"
              @keyup="onChangeSearch($event)"
            />
          </label>
          <v-btn v-if="!searchStatus" @click="searchActive(true)" class="fa" fab>
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn v-else depressed fab class="fa" text>
            <v-icon @click.stop="searchActive(false)">
              mdi-close
            </v-icon>
          </v-btn>
        </form>
        <div v-for="(filter, index) of availableFiltersComputed" :key="`${filter.id}${index}`">
          <div v-if="filter.active && filter.type === 'select'" class="d-flex filters__item">
            <v-autocomplete
              class="filters__item--autocomplete"
              v-model="filter[filter.id]"
              @focus="onFocusSelectFilter($event, filter.id)"
              @change="onChangeSelectFilter($event, filter.id)"
              :items="filtersDictionaries[filter.id]"
              multiple
              return-object
              chips
              clearable
              item-color="#0B583F"
              outlined
              height="45"
              :label="filter.name"
              item-text="label"
              item-value="value"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  v-if="data.index <= 2"
                  :input-value="data.selected"
                  close
                  color="#DBDBDB"
                  text-color="#0B583F"
                  close-icon="mdi-close-thick"
                  @click="data.select"
                  @click:close="removeSelectItem(data.item, filter.id)"
                >
                  {{ data.item.label ? data.item.label : data.item }}
                </v-chip>
                <v-chip color="#0B583F" text-color="#FFFFFF" v-if="data.index === 3">
                  +{{ filter[filter.id].length - 3 }}
                </v-chip>
              </template>
            </v-autocomplete>
            <v-icon class="filters__item--autocomplete-close" @click="removeSelect(filter, index)">
              mdi-close-thick
            </v-icon>
          </div>
          <div v-if="filter.active && filter.type === 'dateRange'" class="d-flex filters__item">
            <v-menu
              :ref="filter.id"
              v-model="filter.menu"
              :close-on-content-click="false"
              :return-value.sync="filter[filter.id]"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="
                    filter[filter.id]
                      ? `${filter[filter.id][0] || ''} ~ ${filter[filter.id][1] || ''}`
                      : ''
                  "
                  label="Дата"
                  prepend-icon="mdi-calendar"
                  class="filters__item--date-range"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="filter[filter.id]" range no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="error" @click="resetDate(filter.id)">
                  Очистить
                </v-btn>
                <v-btn text color="primary" @click="filter.menu = false">
                  Отмена
                </v-btn>
                <v-btn text color="primary" @click="saveDate(filter[filter.id], filter.id)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-icon
              class="filters__item--autocomplete-close"
              @click="removeDateRange(filter, index)"
            >
              mdi-close-thick
            </v-icon>
          </div>
          <div v-if="filter.active && filter.type === 'date'" class="d-flex filters__item">
            <v-menu
              :ref="filter.id"
              v-model="filter.menu"
              :close-on-content-click="false"
              :return-value.sync="filter[filter.id]"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="filter[filter.id] ? `${filter[filter.id]}` : ''"
                  label="Дата"
                  prepend-icon="mdi-calendar"
                  class="filters__item--date-range"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="filter[filter.id]" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="error" @click="resetDate(filter.id)">
                  Очистить
                </v-btn>
                <v-btn text color="primary" @click="filter.menu = false">
                  Отмена
                </v-btn>
                <v-btn text color="primary" @click="saveDateX(filter[filter.id], filter.id)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-icon
              class="filters__item--autocomplete-close"
              @click="removeDateRange(filter, index)"
            >
              mdi-close-thick
            </v-icon>
          </div>
          <div v-if="filter.active && filter.type === 'status'" class="filters__item">
            <v-chip
              close
              color="#DBDBDB"
              class="filters__item--chip"
              text-color="#0B583F"
              close-icon="mdi-close-thick"
              @click:close="removeCheckbox(filter, index)"
            >
              {{ filter.name }}
            </v-chip>
          </div>
          <div
            v-if="filter.active && filter.type === 'customUser'"
            style="display: flex; margin-bottom: 20px; width: 95vh"
          >
            <slot name="customUser"></slot>
          </div>
          <div
            v-if="filter.active && filter.type === 'customProducts'"
            style="display: flex; margin-bottom: 20px; width: 95vh"
          >
            <slot name="customProducts"></slot>
          </div>
        </div>
        <div class="add-filter" v-if="availableFiltersComputed.filter(o => !o.active).length > 0">
          <v-menu
            class="filter-menu"
            content-class="filter-menu"
            open-on-click
            bottom
            right
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-img v-bind="attrs" v-on="on" :src="require('@/assets/components/addIcon.svg')" />
            </template>
            <v-list class="popover-list">
              <v-list-item
                v-for="(item, index) in availableFiltersComputed.filter(o => !o.active)"
                :key="index"
              >
                <v-list-item-title
                  v-if="!item.active"
                  class="popover-list__item"
                  @click="addFilter(item, index)"
                >
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
              <v-img
                class="popover-icon"
                :src="require('@/assets/components/filterPopoverIcon.svg')"
              />
            </v-list>
          </v-menu>
        </div>
        <v-spacer />
        <v-menu
          v-if="extraActions && extraActions.length > 0"
          transition="slide-y-transition"
          offset-y
          bottom
          v-model="menuOpened"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" rounded outlined dark v-bind="attrs" v-on="on">
              Действие
              <v-icon>
                {{ menuOpened ? "mdi-chevron-up" : "mdi-chevron-down" }}
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, i) in extraActions"
              :key="i"
              @click="handleExtraActionClick(item)"
            >
              <v-list-item-icon>
                <v-icon v-if="item.icon" color="primary">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <slot name="last-slot" />
      </v-toolbar>
    </v-row>
    <v-data-table
      v-model="selectedLocal"
      :headers="headers"
      :items="config.pageable ? currentData : data"
      :show-select="showSelect"
      :item-key="itemKey"
      :server-items-length="parseInt(options.size)"
      class="elevation-2 mt-6 pageable-table__data-table"
      fixed-header
      @click:row="clickRow($event)"
      hide-default-footer
      :sort-by.sync="options.sortBy"
      :sort-desc="options.sortDesc"
      @update:sort-desc="onChangeSortDesc"
      ref="table"
    >
      <template v-for="el of headers" :slot="'item.' + el.value" slot-scope="{ item }">
        <component
          :is="el.type"
          :key="el.value"
          :value="el.value"
          :item="item"
          :readonly="el.readonly"
          :child="el.child"
          :loaded-enums="el.enums"
          :width="el.width"
          :store="el.store"
          :class="el.class"
          @set="handleFromChild($event, item)"
        />
      </template>
    </v-data-table>
    <div v-if="config.pageable" class="pagination">
      <div class="d-flex align-baseline justify-space-between">
        <span class="mr-2">Строк на странице:</span>
        <v-text-field
          v-model.number="options.size"
          type="number"
          min="1"
          style="max-width: 50px"
          @keypress="onlyNumber"
          @input.native="onChangePageSize"
        ></v-text-field>
      </div>
      <v-pagination
        :value="+options.page"
        color="#0B583F"
        class="mt-3"
        :length="totalPagesLocal"
        :total-visible="7"
        @input="onChangePage"
      ></v-pagination>
      <div class="d-flex align-baseline">
        <span class="mr-2">Перейти на:</span>
        <v-text-field
          v-model.number="toPage"
          type="number"
          min="1"
          :max="totalPagesLocal"
          style="max-width: 50px"
          class="mr-2"
          @input.native="onChangeToPage"
          @keypress.enter="onChangePage(toPage)"
          @keypress="onlyNumber"
        ></v-text-field>
        <v-btn small @click="onChangePage(toPage)">OK</v-btn>
      </div>
      <div v-if="!$vuetify.breakpoint.mobile">
        <span class="text--disabled"> Всего строк: {{ totalElements }} </span>
      </div>
    </div>
  </v-container>
</template>

<script>
import * as tableComponents from "@/components/crm-table-pageable-new/components/index";
import { debounce } from "lodash";

export default {
  name: "index",
  props: {
    headers: {
      default: () => []
    },
    data: {
      default: () => []
    },
    loadedOptions: {
      type: Object,
      default: () => ({
        page: 1,
        size: 10
      })
    },
    totalElements: {
      default: 0
    },
    totalPages: {
      default: 0
    },
    debounceMs: {
      default: 600
    },
    availableFilters: {
      default: () => []
    },
    filtersDictionaries: {
      default: () => {}
    },
    itemKey: {
      default: ""
    },
    showSelect: {
      default: false
    },
    toolbarShown: {
      type: Boolean,
      default: true
    },
    config: {
      type: Object,
      default: () => {}
    },
    addButtonTitle: {
      type: String,
      default: ""
    },
    searchPlaceholder: {
      type: String,
      default: "Поиск..."
    },
    selected: {
      type: Array,
      default: () => []
    },
    wrapTable: {
      type: Boolean,
      default: true
    },
    tableWrapperMinHeight: {
      type: String,
      default: "calc(100vh - 280px)"
    },
    extraActions: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ...tableComponents
  },
  data() {
    return {
      timeout: null,
      menu: false,
      searchStr: "",
      searchStatus: false,
      random: Math.random(),
      filterValues: {},
      selectedLocal: this.selected,
      toPage: null,
      timerId: null,
      options: {
        ...this.loadedOptions,
        // eslint-disable-next-line radix
        size: parseInt(this.loadedOptions.size),
        sortBy: this.loadedOptions.sort
          ? [this.loadedOptions.sort.split(",")[0]]
          : // eslint-disable-next-line no-void
            void 0,
        sortDesc: this.loadedOptions.sort
          ? [this.loadedOptions.sort.split(",")[1] === "desc"]
          : // eslint-disable-next-line no-void
            void 0
      },
      filtersDictionariesComputed: this.filtersDictionaries,
      totalPagesLocal: this.totalPages,
      menuOpened: false
    };
  },
  watch: {
    searchStr(val) {
      this.search(val);
    },
    selectedLocal(val) {
      this.$emit("setSelected", val);
    },
    filtersDictionaries: {
      handler(val) {
        this.filtersDictionariesComputed = val;
        this.initFilter();
      },
      deep: true,
      immediate: true
    },
    totalPages: {
      handler(val) {
        // eslint-disable-next-line radix
        if (parseInt(this.options.page) > val) {
          this.options.page = 1;
        }
        this.totalPagesLocal = val;
      }
    }
  },
  created() {
    this.initTable();
  },
  computed: {
    enums() {
      return this.$store.getters["entity/getEntity"]("enums");
    },
    currentData() {
      return this.data;
    },
    needToolbar() {
      return (
        this.config.title ||
        this.config.add ||
        this.config.search ||
        this.availableFilters.length > 0
      );
    },
    availablePages() {
      return this.$store.getters.user.availablePages;
    },
    availableFiltersComputed: {
      get() {
        return this.availableFilters;
      },
      set(val) {
        this.$emit("update:availableFilters", val);
      }
    },
    cssProps() {
      return {
        "--table-wrapper-min-height": this.tableWrapperMinHeight
      };
    }
  },
  methods: {
    initTable() {
      // eslint-disable-next-line radix
      if (this.totalPagesLocal && parseInt(this.options.page) > this.totalPagesLocal) {
        this.options.page = 1;
        this.onChangeData();
      }
      this.searchStatus = !!this.options.search;
    },
    findSelectFilterIds(filterId, value) {
      if (!this.filtersDictionariesComputed[filterId]) {
        return null;
      }
      return this.filtersDictionariesComputed[filterId].find(o => o.value.toString() === value);
    },
    initFilter() {
      const { query } = this.$route;
      const optionsKeys = Object.keys(query);
      const statuses = this.$route.query.statuses ? this.$route.query.statuses.split(",") : [];

      this.availableFiltersComputed = this.availableFiltersComputed.map(t => {
        if (statuses.indexOf(t.id) > -1) {
          this.filterValues.statuses = statuses.join(",");
          return {
            ...t,
            active: true
          };
        }
        if (optionsKeys.indexOf(t.id) > -1) {
          let result;
          // eslint-disable-next-line no-void
          const splitValues = query && query[t.id] ? query[t.id].split(",") : void 0;
          if (splitValues && splitValues.length) {
            if (t.type === "select") {
              result = splitValues.map(i => this.findSelectFilterIds(t.id, i)).filter(o => !!o);
              this.filterValues[t.id] = result.map(o => o.value).join(",");
            } else if (t.type === "dateRange") {
              result = splitValues;
              this.filterValues[t.id] = query[t.id];
            }
          }
          return {
            ...t,
            active: true,
            [t.id]: result
          };
        }
        return { ...t };
      });
      // eslint-disable-next-line radix
      if (this.totalPagesLocal && parseInt(this.options.page) > this.totalPagesLocal) {
        this.options.page = 1;
        this.onChangeData();
      }
      this.searchStatus = !!this.options.search;
    },
    onChangeSearch(event) {
      if (event.keyCode === 13) {
        this.options.page = 1;
        // eslint-disable-next-line no-void
        this.options.search = event.target.value ? event.target.value : void 0;
        this.onChangeData();
      }
    },
    onChangeSortDesc(sortDesc) {
      this.options.sortDesc = sortDesc;
      this.onChangeData();
    },
    getDesc(desc) {
      return desc ? "desc" : "asc";
    },
    onChangeData() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        return this.$emit("onChangeData", {
          search: this.options.search,
          page: this.options.page,
          size: this.options.size,
          sort:
            this.options.sortBy && this.options.sortBy.length
              ? `${this.options.sortBy[0]},${this.getDesc(this.options.sortDesc[0])}`
              : // eslint-disable-next-line no-void
                void 0,
          ...this.filterValues
        });
      }, this.debounceMs);
    },
    onlyNumber(event) {
      const { keyCode } = event;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    onChangePageSize(event) {
      if (event.target.value) {
        // eslint-disable-next-line radix
        this.options.size = parseInt(event.target.value) || 1;
        clearTimeout(this.timerId);

        this.timerId = setTimeout(() => {
          this.options.page = 1;
          this.onChangeData();
        }, 1000);
      }
    },
    onChangeToPage(event) {
      if (event.target.value) {
        // eslint-disable-next-line radix
        const toPage = parseInt(event.target.value);
        if (toPage > this.totalPagesLocal) {
          this.toPage = this.totalPagesLocal;
        }
        if (toPage === 0) {
          this.toPage = 1;
        }
      }
    },
    onChangePage(page) {
      this.options.page = page;
      this.onChangeData();
      this.toPage = null;
    },
    saveDate(dates, filterId) {
      this.options.page = 1;
      let checkedDates = [...dates];
      if (this.$moment(dates[0]).isAfter(dates[1])) {
        checkedDates = [...dates].reverse();
      }
      this.filterValues[filterId] = [...checkedDates].join(",");
      this.$refs[filterId][0].save(checkedDates);
      this.onChangeData();
    },
    resetDate(filterId) {
      this.options.page = 1;
      // eslint-disable-next-line no-void
      this.filterValues[filterId] = void 0;
      this.availableFiltersComputed = [...this.availableFiltersComputed].map(i => {
        if (i.id === filterId) {
          return {
            ...i,
            [filterId]: null
          };
        }
        return { ...i };
      });
      this.$refs[filterId][0].save(null);
      this.onChangeData();
    },
    saveDateX(date, filterId) {
      this.options.page = 1;
      this.filterValues[filterId] = [...date];
      this.$refs[filterId][0].save(date);
      this.onChangeData();
    },
    removeSelectItem(item, filterId) {
      this.options.page = 1;
      const availableFiltersComputed = [...this.availableFiltersComputed];
      const filter = this.filterValues[filterId].split(",");
      if (filter.length === 1) {
        // eslint-disable-next-line no-void
        this.filterValues[filterId] = void 0;
      } else {
        this.filterValues[filterId] = filter.filter(o => o !== item.value).join(",");
      }

      this.availableFiltersComputed = availableFiltersComputed.map(o => {
        if (o.id === filterId) {
          return {
            ...o,
            [filterId]: o[filterId].filter(f => f !== item)
          };
        }
        return { ...o };
      });

      this.onChangeData();
    },
    removeDateRange(filter) {
      this.options.page = 1;
      // eslint-disable-next-line no-void
      this.filterValues[filter.id] = void 0;
      this.availableFiltersComputed = [...this.availableFiltersComputed].map(i => {
        if (i.id === filter.id) {
          return {
            ...i,
            [filter.id]: null,
            active: false
          };
        }
        return { ...i };
      });

      this.onChangeData();
    },
    removeSelect(filter) {
      this.options.page = 1;
      // eslint-disable-next-line no-void
      this.filterValues[filter.id] = void 0;
      this.availableFiltersComputed = this.availableFiltersComputed.map(i => {
        if (i.id === filter.id) {
          return {
            ...i,
            // eslint-disable-next-line no-void
            [filter.id]: void 0,
            active: false
          };
        }
        return { ...i };
      });
      this.onChangeData();
    },
    removeCheckbox(filter) {
      this.options.page = 1;
      const filters = this.filterValues.statuses.split(",");
      const newFilterValues = filters.filter(o => o !== filter.id);
      if (!newFilterValues.length) {
        delete this.filterValues.statuses;
      } else {
        this.filterValues.statuses = filters.filter(o => o !== filter.id).join(",");
      }
      this.availableFiltersComputed = this.availableFiltersComputed.map(i => {
        if (i.id === filter.id) {
          return {
            ...i,
            active: false
          };
        }
        return { ...i };
      });
      this.onChangeData();
    },
    addFilter(filter) {
      if (filter.type === "status") {
        this.onChangeConditionFilter(filter);
      }
      this.availableFiltersComputed = this.availableFiltersComputed.map(i => {
        if (i.id === filter.id) {
          return {
            ...i,
            active: true
          };
        }
        return { ...i };
      });
    },
    onChangeSelectFilter(event, filterId) {
      this.options.page = 1;
      if (Array.isArray(event)) {
        // eslint-disable-next-line no-void
        this.filterValues[filterId] = event.length ? event.map(o => o.value).join(",") : void 0;
      } else {
        this.filterValues[filterId] = { ...event };
      }
      this.onChangeData();
    },
    onFocusSelectFilter(event, filterId) {
      this.$emit("onFocusSelectFilter", filterId);
    },
    onChangeConditionFilter(filter) {
      this.options.page = 1;
      let temp = [];
      // eslint-disable-next-line no-empty,no-prototype-builtins
      if (!this.filterValues.hasOwnProperty("statuses")) {
        this.filterValues.statuses = undefined;
        temp.push(filter.id);
      } else {
        temp = this.filterValues.statuses.split(",");
        temp.push(filter.id);
      }
      this.filterValues.statuses = temp.join(",");
      this.onChangeData();
    },
    handleFromChild(element, item) {
      this.$emit("handleActions", {
        type: element.type,
        event: element.event,
        item
      });
    },
    clickRow(item) {
      if (!this.config.disableRowClick) {
        this.$emit("handleActions", {
          type: "info",
          item
        });
      }
    },
    searchActive(status) {
      const element = document.getElementById(this.random.toString());
      if (status) {
        element.classList.add("search-active");
      } else if (element.classList.contains("search-active")) {
        element.classList.remove("search-active");
        if (this.options.search) {
          // eslint-disable-next-line no-void
          this.options.search = void 0;
          this.options.page = 1;
          this.onChangeData();
        }
      }

      if (!status) {
        this.searchStr = "";
      }
      this.searchStatus = status;
    },
    search(str) {
      this.options.search = str;
      this.onChangeData();
    },
    handleExtraActionClick(item) {
      if (item.action === "noAction" && item.type === "reports") {
        this[`$${item.type}`].get(item.url).then(res => {
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(res);
          a.download = item.fileName;
          a.click();
        });
      } else {
        this.$emit("extraAction", item.action);
      }
    }
  }
};
</script>

<style lang="scss">
.pageable-table {
  .v-simple-checkbox--disabled {
    cursor: not-allowed;
  }
  .v-toolbar {
    height: auto !important;
  }
  .v-toolbar__content,
  .v-toolbar__extension {
    flex-wrap: wrap;
    height: auto !important;
  }

  .v-data-table {
    border-radius: 10px 10px 0 0;

    .v-data-table__wrapper {
      max-height: var(--table-wrapper-min-height);
      overflow-y: auto;
      & table > tbody > tr > td {
        white-space: nowrap;
      }
      .v-data-table-header {
        th.active {
          background-color: white !important;
          border-radius: 0 !important;
          span {
            font-weight: bolder;
          }
        }
        th.sortable {
          width: 165px;
          min-width: 165px;
        }
      }
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 10px;
      }
    }
  }
  .pagination {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    font-size: 14px;
    gap: 5px;
  }
}

.filters {
  &__item {
    height: 44px;
    margin-right: 11px;
    margin-top: 3px;
    margin-bottom: 4px;

    &.v-application .primary--text {
      color: #737373 !important;
      caret-color: #737373 !important;
    }

    &--chip {
      margin-top: 2px;
      min-height: 40px;
      background: #dbdbdb;
      border-radius: 22px !important;
      position: relative;

      .mdi-close-thick::before {
        font-size: 16px;
      }
    }

    &--date-range {
      border-radius: 22px !important;
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);

      .theme--light.v-label {
        color: #737373;
      }
      .v-input__prepend-outer {
        margin-top: 10px;
        padding-left: 10px;
        margin-right: 0;
      }

      .v-text-field--filled > .v-input__control > .v-input__slot,
      .v-text-field--full-width > .v-input__control > .v-input__slot,
      .v-text-field--outlined > .v-input__control > .v-input__slot {
        min-height: 45px !important;
      }

      &.v-text-field--outlined .v-label {
        top: 13px;
      }

      &.v-text-field--enclosed .v-input__append-inner {
        margin-top: 10px;
      }

      &.v-text-field--outlined > .v-input__control > .v-input__slot {
        min-height: 45px !important;
        box-shadow: none !important;
      }
      &.v-text-field--outlined fieldset {
        border: none;
      }
      &.theme--light.v-chip:not(.v-chip--active) {
        background: #b9c9c4 !important;
        color: #737373;
      }
      i.mdi-close-thick {
        margin-left: 10px;
      }
      .mdi-close-thick::before {
        font-size: 14px;
      }
    }

    &--autocomplete {
      border-radius: 22px !important;
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
      width: min-content;
      min-width: 200px;

      .v-select__selections {
        flex-wrap: nowrap;
      }

      .theme--light.v-label {
        color: #737373;
      }

      .v-text-field--filled > .v-input__control > .v-input__slot,
      .v-text-field--full-width > .v-input__control > .v-input__slot,
      .v-text-field--outlined > .v-input__control > .v-input__slot {
        min-height: 45px !important;
      }

      &.v-text-field--outlined .v-label {
        top: 13px;
      }

      &.v-text-field--enclosed .v-input__append-inner {
        margin-top: 10px;
      }

      &.v-text-field--outlined > .v-input__control > .v-input__slot {
        min-height: 45px !important;
        box-shadow: none !important;
      }
      &.v-text-field--outlined fieldset {
        border: none;
      }
      &.theme--light.v-chip:not(.v-chip--active) {
        background: #b9c9c4 !important;
        color: #737373;
      }
      .v-chip.v-size--default {
        border-radius: 22px !important;
        font-size: 14px !important;
        height: 22px !important;
      }
      i.mdi-close-thick {
        margin-left: 10px;
      }
      .mdi-close-thick::before {
        font-size: 14px;
      }
    }

    &--autocomplete-close {
      margin-left: 5px;
      cursor: pointer;
      &.mdi-close-thick::before {
        font-size: 16px;
      }
      &.v-icon.v-icon::after {
        height: 0 !important;
      }
    }
  }
}
.add-filter {
  cursor: pointer;
}
.filter-menu {
  margin-left: 5px;
  &.v-menu__content {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
    overflow-y: visible;
    overflow-x: visible;
    contain: none;
    border-radius: 12px;
  }
}
.popover-list {
  &__item {
    cursor: pointer;
    border-radius: 22px;
    padding: 7px 17px;
  }
  &__item:hover {
    background: #dbdbdb;
  }
  &.v-sheet.v-list {
    position: relative;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 12px;
  }
  .v-list-item {
    min-height: 20px;
    padding: 0 12px;
  }
  .popover-icon {
    position: absolute;
    background-color: transparent;
    top: -5px;
    left: 10px;
  }
}
.search-form {
  position: relative;
  transition: all 0.2s;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  border-radius: 25px;
  border: 4px solid white;
  padding: 5px;
  color: black;
  margin-right: 4px;

  .v-btn--fab.v-size--default {
    width: 42px;
    height: 42px;
  }

  &.search-active {
    width: 300px;
    cursor: pointer;
  }

  &.search-active input {
    display: block;
  }

  &.search-active .fa {
    color: black;
  }

  .search-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 42.5px;
    line-height: 30px;
    outline: 0;
    display: none;
    font-size: 1em;
    border-radius: 20px;
    padding: 0 20px;
    background: white;
    border: 1px solid gray;
  }

  .fa {
    box-sizing: border-box;
    padding: 10px;
    width: 42.5px;
    height: 42.5px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    color: #07051a;
    text-align: center;
    font-size: 1.2em;
    transition: all 1s;
  }
}

.create-button {
  &.theme--light.v-btn {
    color: #ffffff;
  }
}
td .client {
  max-width: 22ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
</style>
