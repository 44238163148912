<template>
  <v-text-field v-if="item['editing']"
                type="number"
                :rules="item['rules'][value]"
                :error="item['error']"
                :disabled="item.disabled"
                v-model="item[value]"
                oninput="if (this.value < 0) this.value = 0;"
                @change="onChange">
  </v-text-field>
  <span v-else>{{ item[value] }}</span>
</template>

<script>
export default {
  name: 'tableNumberField',
  props: {
    value: {
      default: '',
    },
    item: {
      default: () => {},
    },
  },
  methods: {
    onChange(val) {
      this.$emit('set', { type: 'tableNumberField', event: { value: val } });
    },
  },
};
</script>

<style scoped>

</style>
