<template>
  <span> {{computedElement}}</span>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'dictionary',
  props: {
    value: {
      default: '',
    },
    store: {
      default: '',
    },
    item: {
      default: () => {},
    },
  },
  created() {
  },
  computed: {
    computedElement() {
      const find = this.$store.getters['dictionary/getDictionary'](this.store).find((i) => i.id === get(this.item, this.value));
      if (find) {
        return find.nameRu;
      } return '';
    },
  },
};
</script>

<style scoped>

</style>
