<template>
  <v-col class="pa-0 ma-0" :style="`width: ${width}`">
    <v-select
      :items="enums"
      label="Выберите причину"
      @change="onChange"
      :disabled="item.disabled"
      v-model="item[value]"
      outlined
      hide-details
      clearable
      class="my-2"
      full-width
    ></v-select>
  </v-col>
</template>

<script>
export default {
  name: 'tableSelect',
  props: {
    item: {
      default: () => {},
    },
    value: {
      default: '',
    },
    loadedEnums: {
      default: '',
    },
    width: {
      default: 'auto',
    },
  },
  computed: {
    enums() {
      if (this.loadedEnums) {
        const enums = this.$store.getters['entity/getEntity'](this.loadedEnums);
        return Object.entries(enums).map(([key, text]) => ({ value: key, text }));
      }
      return [];
    },
  },
  methods: {
    onChange(val) {
      this.$emit('set', { type: 'tableSelect', event: { value: val } });
    },
  },
};
</script>

<style scoped>

</style>
