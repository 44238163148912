import { render, staticRenderFns } from "./vuexDictionary.vue?vue&type=template&id=7ba76868&scoped=true"
import script from "./vuexDictionary.vue?vue&type=script&lang=js"
export * from "./vuexDictionary.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ba76868",
  null
  
)

export default component.exports