<template>
  <span>
    {{computedLabel}}
  </span>
</template>

<script>

import lodash from 'lodash';

export default {
  name: 'default',
  props: {
    value: {
      default: '',
    },
    item: {
      default: () => {},
    },
    store: {},
  },
  computed: {
    computedLabel() {
      const arr = this.value.split(',');
      const values = lodash.get(this.item, this.store);
      const list = [];

      arr.forEach((i) => {
        if (values[i]) {
          list.push(values[i]);
        }
      });
      return list.join(',');
    },
  },
};
</script>

<style scoped>

</style>
